@font-face {
  font-family: 'Indulta SemiSerif';
  src: url('./resources/fonts/IndultaSemiSerif.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

h1 {
  margin-bottom: 1rem;
}

h2 {
  font-family: 'Indulta SemiSerif', sans-serif;
}

body {
  font-family: Arial, sans-serif;

  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

p {
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: left;
}

a {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
}

.container {
  max-width: 40rem;
  padding: 2rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.code {
  display: inline-block;
  font-family: 'Courier New', Courier, monospace;
  font-size: 2em;
  font-weight: bold;
  background-color: #f8f9fa; /* light grey */
  padding: 5px;
  border-radius: 5px; 
  text-align: center;
}

.header {
  font-family: 'Indulta SemiSerif', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.logo-image {
  width: 2em;
  height: auto;
  border-radius: 10%; 
  object-fit: cover; 
}

.signature {
  font-family: Arial, sans-serif; 
  font-size: 1rem; 
  line-height: 1.5; 
  color: #333;
  text-align: left;
  margin-top: 20px;
  padding-top: 20px;
}

.footer {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 0.8rem;
  color: #777;
}